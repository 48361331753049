@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.Gamehomepage-container {
    text-align: center;
    padding: 40px 20px;
    background: url('./images/gamehomebackgound-desktop2.png') no-repeat center center fixed;
    background-size: cover;
    color: white;
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
}

.Gamehomepage-header {
    margin-bottom: 60px;
    color: #ffffff;
    /* Ensuring text is fully white for contrast */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    /* Adding text shadow for better readability */
}

.games-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.Gamehome-Text {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #ffffff;
    /* Ensuring text is fully white for contrast */
    text-shadow: 4px 4px 4px #132da1;
    /* Adding text shadow for better readability */
}

.Gamehome-SubText {
    font-size: 1.5rem;
    font-weight: 400;
    color: #132da1;
    /* Ensuring text is fully white for contrast */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    /* Adding text shadow for better readability */
}

.game-card {
    width: 350px;
    height: 250px;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.game-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.8);
}

.game-info {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    border-radius: 0 0 20px 20px;
    /* Rounded bottom corners */
    text-align: left;
}



/* Responsive Adjustments for Mobile Devices */
@media (max-width: 768px) {
    .Gamehomepage-container {
        background: url('./images/gamehomebackgound-mobile.png') no-repeat center center fixed;
        /* Different background for mobile */
        padding: 20px 10px;
        background-size: cover;
        /* Reduced padding */
    }

    .Gamehomepage-header {
        margin-bottom: 40px;
        /* Smaller margin */
    }

    .games-container {
        gap: 20px;
        /* Smaller gap */
    }

    .game-card {
        width: 100%;
        /* Full width on mobile */
        height: 200px;
        /* Shorter cards */
    }

    .Gamehome-Text {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 20px;
        color: #ffffff;
        /* Ensuring text is fully white for contrast */
        text-shadow: 5px 5px 5px #132da1;
        /* Adding text shadow for better readability */
    }
}